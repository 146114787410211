import Grow from "@mui/material/Grow";
import {
  DeFlagIcon,
  DevFlagIcon,
  FrFlagIcon,
  UkFlagIcon,
} from "ds_legacy/components/FlagIcon";
import { RSFAB } from "ds_legacy/components/RSButton";
import { dp, sizing } from "ds_legacy/materials/metrics";
import { useScreenReaderStyles } from "ds_legacy/materials/screenreaders";
import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useLanguageChange } from "reduxentities/actions";
import { State } from "reduxentities/selectors/index";

const TIMEOUT = 500;

const LANGUAGE_FLAGS: {
  [key: string]: React.ComponentType<{ active?: boolean }>;
} = { en: UkFlagIcon, de: DeFlagIcon, fr: FrFlagIcon, dev: DevFlagIcon };

function LanguageFabs({
  checked,
  flags,
  onFlagChange,
  toggleCheck,
}: {
  checked: boolean;
  flags: {
    [key: string]: React.ComponentType<{ active?: boolean }>;
  };
  onFlagChange: Function;
  toggleCheck: Function;
}) {
  return (
    <>
      {Object.keys(flags).map((flag, index, flagsArray) => {
        const Flag = flags[flag];
        const inTransitionDelay = `${(flagsArray.length - index) * 0.1}s`;
        const outTransitionDelay = `${index * 0.1}s`;
        return (
          <Grow
            key={flag}
            in={checked}
            style={{
              transitionDelay: checked ? inTransitionDelay : outTransitionDelay,
            }}
            {...(checked ? { timeout: TIMEOUT } : {})}
          >
            <div style={{ marginBottom: sizing(2) }}>
              <RSFAB
                id={`language-${flag}`}
                inverted
                onClick={() => {
                  onFlagChange(flag);
                  toggleCheck();
                }}
              >
                <Flag active />
              </RSFAB>
            </div>
          </Grow>
        );
      })}
    </>
  );
}

const defaultFabContainerStyles: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  flex: "0 0 auto",
  position: "fixed",
  bottom: dp(100),
  left: dp(30),
  zIndex: 100,
};

export function FlagFab({
  containerStyleOverride,
  flags,
  flagState,
  onFlagChange,
}: {
  containerStyleOverride?: CSSProperties;
  flagState: string | undefined;
  flags: {
    [key: string]: React.ComponentType<{ active?: boolean }>;
  };
  onFlagChange: AnyFunction;
}) {
  const location = useLocation();
  const [checked, setChecked] = useState(false);
  const toggleCheck = useCallback(() => setChecked(!checked), [checked]);
  const Flag = flags[flagState || ""];
  const [renderPicker, setRenderPicker] = useState(checked);
  const { srOnly } = useScreenReaderStyles();

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (checked) {
      setRenderPicker(true);
    } else {
      timeout = setTimeout(() => {
        setRenderPicker(false);
      }, TIMEOUT);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [checked]);

  useEffect(() => {
    setChecked(false);
  }, [location.pathname]);

  return (
    <div
      style={
        containerStyleOverride
          ? containerStyleOverride
          : defaultFabContainerStyles
      }
    >
      {renderPicker && (
        <LanguageFabs
          flags={flags}
          checked={checked}
          onFlagChange={onFlagChange}
          toggleCheck={toggleCheck}
        />
      )}
      <RSFAB
        sx={{ displayPrint: "none" }}
        inverted
        onClick={toggleCheck}
        id="language-switcher"
      >
        <Flag />
        <span className={srOnly}>{flagState}</span>
      </RSFAB>
    </div>
  );
}

export default function LanguageFab() {
  const onLanguageChange = useLanguageChange();
  const language = useSelector((s: State) => s?.settings?.language);

  return (
    <FlagFab
      flags={LANGUAGE_FLAGS}
      flagState={language}
      onFlagChange={onLanguageChange}
    />
  );
}

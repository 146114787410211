import makeStyles from "@mui/styles/makeStyles";
import { dp } from "./metrics";

export const useScreenReaderStyles = makeStyles({
  srOnly: {
    position: "absolute",
    left: dp(-10000),
    top: "auto",
    width: dp(1),
    height: dp(1),
    overflow: "hidden",
  },
});

import { Modal } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import RSButton from "ds_legacy/components/RSButton";
import { HINT_NUDGE_BACKGROUND } from "ds_legacy/materials/colors";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import {
  border,
  dp,
  margin,
  padding,
  responsiveCSS,
  sizing,
} from "ds_legacy/materials/metrics";
import { Body, FONT_SIZE_16, LINE_HEIGHT_20 } from "ds_legacy/materials/typography";
import { useFocusElement } from "dsl/hooks/useFocusElement";
import { useEffect, useState } from "react";
import styled from "styled-components";

const HintNudgeWrapper = styled.div<{ position: { x: number; y: number } }>`
  position: absolute;
  min-height: ${sizing(13)};
  max-width: ${sizing(43)};
  left: ${({ position }) => dp(position.x)};
  top: ${({ position }) => dp(position.y)};
  border-radius: ${dp(2)};
  padding: ${dp(1)};
  background: ${HINT_NUDGE_BACKGROUND};
  ${responsiveCSS({
    mobile: [`top: ${sizing(23)}`, `left: ${sizing(1)}`, `right: ${sizing(1)}`],
  })};
`;

const HintNudgePointer = styled.div`
  position: absolute;
  top: -9px;
  left: 8px;
  width: 0;
  height: 0;
  border-left: ${border({ width: 12, color: "transparent" })};
  border-right: ${border({ width: 12, color: "transparent" })};
  border-bottom: ${border({ width: 14, color: HINT_NUDGE_BACKGROUND })};
`;

const HintNudgeActionBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const buttonStyles = {
  margin: margin(0),
};

export const NUDGE_LOCALSTORAGE_KEY = "didShowFiltersNudge";
const NUDGE_LOCALSTORAGE_VALUE = "true";

const useShowNudge = (): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
] => {
  const [showNudge, setShowNudge] = useState<boolean>(false);

  useEffect(() => {
    if ((window as { IN_STORYBOOK: boolean }).IN_STORYBOOK) return;

    const didShowFiltersNudge = window.localStorage.getItem(
      NUDGE_LOCALSTORAGE_KEY,
    );
    if (didShowFiltersNudge === NUDGE_LOCALSTORAGE_VALUE) return;

    window.localStorage.setItem(
      NUDGE_LOCALSTORAGE_KEY,
      NUDGE_LOCALSTORAGE_VALUE,
    );

    const timeout = setTimeout(() => {
      setShowNudge(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [showNudge, setShowNudge]);

  return [showNudge, setShowNudge];
};

export function HintNudge({
  confirmText,
  heading,
  position,
  text,
}: {
  confirmText: string;
  heading?: string;
  position: { x: number; y: number };
  text: string;
}) {
  const [showNudge, setShowNudge] = useShowNudge();
  const firstFocusableElement = useFocusElement<HTMLButtonElement>({
    dependencies: [showNudge],
    shouldFocus: !showNudge,
  });

  if (!showNudge) return null;

  return (
    <Modal open={showNudge}>
      <HintNudgeWrapper position={position}>
        <h2 style={visuallyHidden}>{heading}</h2>
        <HintNudgePointer />
        <VerticalLayout
          margin={margin(3, 0.5, 0, 0)}
          padding={padding(0, 1, 1, 0)}
        >
          <Body lineHeight={LINE_HEIGHT_20} fontSize={FONT_SIZE_16}>
            {`☝🏻 ${text}`}
          </Body>
          <HintNudgeActionBox>
            <RSButton
              color="primary"
              id="got_it"
              loading="na"
              onClick={() => setShowNudge(false)}
              ref={firstFocusableElement}
              variant="text"
              style={buttonStyles}
            >
              {confirmText}
            </RSButton>
          </HintNudgeActionBox>
        </VerticalLayout>
      </HintNudgeWrapper>
    </Modal>
  );
}
